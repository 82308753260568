import React, { useState, useEffect } from "react"
import { Container, Row, Col, Button } from "react-bootstrap"
import $ from "jquery"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import InstagramModalGallery from "./InstagramModalGallery"
import MobGallery from "./mob-gallery-comp"

function Instagram(props) {
  const [showImage, setShowImage] = useState(false)
  const [imageToOpen, setImageToOpen] = useState("")
  // const [imageHeight, setImageHeight] = useState(0)
  const [windowWidth, setWindowWidth] = useState(0)
  const [imagesToModal, setImagesToModal] = useState([])

  const imageHeight = 0;

  const setVideoWindow = () => {
    setWindowWidth($(window).width())
  }

  useEffect(() => {
    let imgArray = []
    props.imagesArray.forEach(element => {
      imgArray.push({ childImageSharp: element["childrenImageSharp"][0] })
    })
    setImagesToModal(imgArray)
  }, [props])

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWindowWidth($(window).width())
      window.addEventListener("resize", setVideoWindow)
    }
  }, [])

  const handleOpen = k => {
    setImageToOpen(k)
    setShowImage(true)
  }

  const hideInstagram = () => {
    setShowImage(false)
  }

  const mountImages = () => {
    return props.imagesArray.map((image, key) => {
      var imgObj = {}
      imgObj[["childImageSharp"]] = image[Object.keys(image)[0]][0]
      return (
        <Col sm={4} className="instagram-col" key={key}>
          <Button
            onClick={e => handleOpen(key)}
            className="instagram-button"
            aria-label="instagram-button"
          >
            <div className="brand-wrapper">
              <div className="brand-image-wrapper">
                <div className="img-hover-zoom">
                  <GatsbyImage
                    image={getImage(imgObj)}
                    backgroundColor={false}
                    alt="instagram"
                    className="img-responsive img-instagram"
                  />
                </div>
              </div>
            </div>
          </Button>
        </Col>
      )
    })
  }

  return (
    <>
      <InstagramModalGallery
        show={showImage}
        handleOpen={handleOpen}
        handleClose={hideInstagram}
        images={imagesToModal}
        firstImage={imageToOpen}
        imageHgt={imageHeight}
      />
      <Container>
        <Row>
          <Col>
            <div className="text-center">
              <h4 className="marcas-title" style={{ color: "#606060" }}>
                novedades
              </h4>
            </div>
          </Col>
        </Row>
      </Container>
      {windowWidth >= 768 ? (
        <Container className="big-container">
          <Row>
            <Col sm={1} />
            <Col sm={10}>
              <div className="height20" />
              <Container>
                <Row>{mountImages()}</Row>

                <div className="height40" />
                <div className="height40" />
              </Container>
            </Col>
          </Row>
        </Container>
      ) : (
        <MobGallery
          imagesArray={props.imagesArray}
          ratio={1}
          company="instagram"
          windowWidth={windowWidth}
          leftArrow={props.leftArrow}
          rightArrow={props.rightArrow}
        />
      )}
    </>
  )
}

export default Instagram
