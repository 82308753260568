import React, { useState, useEffect } from "react"
import { Container, Row, Col, Button } from "react-bootstrap"
import $ from "jquery"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Icon } from "react-icons-kit"
import { mapPin } from "react-icons-kit/feather/mapPin"
import Metatags from "../components/metatags"
import Navbar from "../components/navbar"
import Instagram from "../components/instagram"
import MapModal from "../components/MapModal"
import Footer from "../components/footer"
import "../css/mob-gallery.css"

import SvgDaucodesigns from "../svg/daucodesigns-site.svg"

function IndexPage({ data }) {
  const [showMaps, setShowMaps] = useState(false)
  const [mapCoords, setMapCoords] = useState({})
  const [windowWidth, setWindowWidth] = useState(0)

  const setVideoWindow = () => {
    setWindowWidth($(window).width())
  }

  useEffect(() => {
    setWindowWidth($(window).width())
    if (typeof window !== "undefined") {
      window.addEventListener("resize", setVideoWindow)
    }
  }, [])

  const hideMaps = () => {
    setShowMaps(false)
  }

  const handleMaps = coords => {
    setShowMaps(true)
    setMapCoords(coords)
  }

  return (
    <>
      <Metatags
        title="daucodesigns"
        description="detrás de cada producto, hay una historia"
        url="https://daucodesigns.com"
        image="https://daucodesigns.com/logo-daucodesigns.png"
        keywords="muebles, design"
      />
      {windowWidth ? <></> : <></>}
      <Navbar />
      <MapModal
        show={showMaps}
        handleOpen={handleMaps}
        handleClose={hideMaps}
        coords={mapCoords}
      />
      <div id="logo-wrapper" style={{ marginTop: "56px" }}>
        <div id="logo-conteiner">
          <SvgDaucodesigns id="logo-title" />
          <div className="text-center">
            <h4 id="logo-slogan">detrás de cada producto, hay una historia</h4>
          </div>
        </div>
        <span className=" inline-block" id="logo-bg">
          <picture>
            <source srcSet="./blastation1P.webp" type="image/webp" />
            <source srcSet="./blastation1P.jpg" type="image/jpeg" />
            <img src="./blastation1P.jpg" alt="daucodesigns" width="1920" height="1000" style={{width:"100%", height:"auto"}}/>
          </picture>
        </span>
      </div>
      <div className="height40" />
      <Container className="big-container">
        <Row>
          <Col md={3} />
          <Col md={6}>
            <p className="card-content text-center">
              Una coleción de productos diferenciados para todas aquellas
              personas que son conscientes que el diseño de interiores es
              también una manera de expresar las ideas y voluntades de un hogar
              o un negocio.
            </p>
          </Col>
        </Row>
        <div className="height20" />
        <Row>
          <Col md={6} style={{marginBottom:"20px"}}>
            <GatsbyImage
              image={getImage(data.bene_pixel)}
              backgroundColor={false}
              alt="daucodesigns"
            />
          </Col>
          <Col md={6} style={{marginBottom:"20px"}}>
            <GatsbyImage
              image={getImage(data.bla_stattion_oppo)}
              backgroundColor={false}
              alt="daucodesigns"
            />
          </Col>
        </Row>
      </Container>

      <div className="height40" />

      <div id="marcas">
        <div className="text-center">
          <h4 className="marcas-title">marcas exclusivas</h4>
        </div>
        <div className="text-center">
          <h4 id="marcas-slogan">
            estas son las marcas premium que distribuimos en exclusiva
          </h4>
        </div>
        <div className="height20" />

        <Container className="big-container">
          <Row>
            <Col md={6} className="brand-col">
              <Link to="/bla-station">
                <div className="brand-wrapper">
                  <div className="brand-image-wrapper">
                    <div className="img-hover-zoom">
                      <GatsbyImage
                        image={getImage(data.blastation7P)}
                        backgroundColor={false}
                        alt="blå station"
                      />
                    </div>
                  </div>
                  <div className="text-center brand-button-wrapper">
                    <Button className="brand-button">blå station</Button>
                  </div>
                </div>
              </Link>
              <div className="height40" />
            </Col>

            <Col md={6} className="brand-col">
              <Link to="/bene">
                <div className="brand-wrapper">
                  <div className="brand-image-wrapper">
                    <div className="img-hover-zoom">
                      <GatsbyImage
                        image={getImage(data.bene5P)}
                        backgroundColor={false}
                        alt="bene"
                      />
                    </div>
                  </div>
                  <div className="text-center brand-button-wrapper">
                    <Button className="brand-button">bene</Button>
                  </div>
                </div>
              </Link>
            </Col>
          </Row>
          <div className="height40" />
          <Row>
            <Col md={6} className="brand-col">
              <Link to="/acousticpearls">
                <div className="brand-wrapper">
                  <div className="brand-image-wrapper">
                    <div className="img-hover-zoom">
                      <GatsbyImage
                        image={getImage(data.acousticpearls1P)}
                        backgroundColor={false}
                        alt="daucodesigns"
                      />
                    </div>
                  </div>
                  <div className="text-center brand-button-wrapper">
                    <Button className="brand-button">acousticpearls</Button>
                  </div>
                </div>
              </Link>
              <div className="height40" />
            </Col>
            <Col md={6} className="brand-col">
              <Link to="/wiesner-hager">
                <div className="brand-wrapper">
                  <div className="brand-image-wrapper">
                    <div className="img-hover-zoom">
                      <GatsbyImage
                        image={getImage(data.wiesnerhager7P)}
                        backgroundColor={false}
                        alt="wiesner hager"
                      />
                    </div>
                  </div>
                  <div className="text-center brand-button-wrapper">
                    <Button className="brand-button">wiesner hager</Button>
                  </div>
                </div>
              </Link>
            </Col>
          </Row>
          <div className="height40" />
          <Row>
            <Col md={4} />
            <Col md={4} className="align-center">
              <Link to="/nuestras-marcas">
                <Button className="brand-button">nuestras marcas</Button>
              </Link>
            </Col>
          </Row>
        </Container>
        <div className="height40" />
        <div className="height40" />
      </div>
      <div className="text-center">
        <h4 className="marcas-title" style={{ color: "#606060" }}>
          marcas seleccionadas
        </h4>
      </div>
      <div className="text-center">
        <h4 id="marcas-slogan" style={{ color: "#606060" }}>
          marcas premium que distribuimos en España
        </h4>
      </div>
      <div className="height20" />

      <Container className="big-container">
        <Row>
          <Col md={3} className="brand-col">
            <Link to="/grassoler">
              <div className="brand-wrapper">
                <div className="brand-image-wrapper">
                  <div className="img-hover-zoom">
                    <GatsbyImage
                      image={getImage(data.grassoler1P)}
                      backgroundColor={false}
                      alt="grassoler"
                    />
                  </div>
                </div>
                <div className="text-center brand-button-wrapper">
                  <Button className="brand-button">grassoler</Button>
                </div>
              </div>
            </Link>
            <div className="height40" />
          </Col>
          <Col md={3} className="brand-col">
            <Link to="/inclass">
              <div className="brand-wrapper">
                <div className="brand-image-wrapper">
                  <div className="img-hover-zoom">
                    <GatsbyImage
                      image={getImage(data.Inclass1P)}
                      backgroundColor={false}
                      alt="inclass"
                    />
                  </div>
                </div>
                <div className="text-center brand-button-wrapper">
                  <Button className="brand-button">inclass</Button>
                </div>
              </div>
            </Link>
            <div className="height40" />
          </Col>
          <Col md={3} className="brand-col">
            <Link to="/gotessons">
              <div className="brand-wrapper">
                <div className="brand-image-wrapper">
                  <div className="img-hover-zoom">
                    <GatsbyImage
                      image={getImage(data.Gotessons1P)}
                      backgroundColor={false}
                      alt="gotessons"
                    />
                  </div>
                </div>
                <div className="text-center brand-button-wrapper">
                  <Button className="brand-button">gotessons</Button>
                </div>
              </div>
            </Link>
            <div className="height40" />
          </Col>
          <Col md={3} className="brand-col">
            <Link to="/nardi">
              <div className="brand-wrapper">
                <div className="brand-image-wrapper">
                  <div className="img-hover-zoom">
                    <GatsbyImage
                      image={getImage(data.Nardi1P)}
                      backgroundColor={false}
                      alt="nardi"
                    />
                  </div>
                </div>
                <div className="text-center brand-button-wrapper">
                  <Button className="brand-button">nardi</Button>
                </div>
              </div>
            </Link>
            <div className="height40" />
          </Col>
        </Row>
        <Row>
        <Col md={3} className="brand-col">
            <Link to="/systemtronic">
              <div className="brand-wrapper">
                <div className="brand-image-wrapper">
                  <div className="img-hover-zoom">
                    <GatsbyImage
                      image={getImage(data.systemtronic1P)}
                      backgroundColor={false}
                      alt="systemtronic"
                    />
                  </div>
                </div>
                <div className="text-center brand-button-wrapper">
                  <Button className="brand-button">systemtronic</Button>
                </div>
              </div>
            </Link>
            <div className="height40" />
          </Col>
          <Col md={3} className="brand-col">
            <Link to="/artis">
              <div className="brand-wrapper">
                <div className="brand-image-wrapper">
                  <div className="img-hover-zoom">
                    <GatsbyImage
                      image={getImage(data.Artis1P)}
                      backgroundColor={false}
                      alt="artis"
                    />
                  </div>
                </div>
                <div className="text-center brand-button-wrapper">
                  <Button className="brand-button">artis</Button>
                </div>
              </div>
            </Link>
            <div className="height40" />
          </Col>
          <Col md={3} className="brand-col">
            <Link to="/kastel">
              <div className="brand-wrapper">
                <div className="brand-image-wrapper">
                  <div className="img-hover-zoom">
                    <GatsbyImage
                      image={getImage(data.Kastel1P)}
                      backgroundColor={false}
                      alt="kastel"
                    />
                  </div>
                </div>
                <div className="text-center brand-button-wrapper">
                  <Button className="brand-button">kastel</Button>
                </div>
              </div>
            </Link>
            <div className="height40" />
          </Col>
          <Col md={3} className="brand-col">
            <Link to="/fenabel">
              <div className="brand-wrapper">
                <div className="brand-image-wrapper">
                  <div className="img-hover-zoom">
                    <GatsbyImage
                      image={getImage(data.fenabel1P)}
                      backgroundColor={false}
                      alt="fenabel"
                    />
                  </div>
                </div>
                <div className="text-center brand-button-wrapper">
                  <Button className="brand-button">fenabel</Button>
                </div>
              </div>
            </Link>
            <div className="height40" />
          </Col>
        </Row>
      </Container>
      <Container>
        <div className="height40" />
        <Row>
          <Col md={4} />
          <Col md={4} className="align-center">
            <Link to="/nuestras-marcas">
              <Button className="brand-button">nuestras marcas</Button>
            </Link>
          </Col>
        </Row>
        <div className="height40" />
        <div className="height40" />
      </Container>

      <div id="shop-online">
        <Container>
          <Row>
            <Col>
              <div className="text-center">
                <h4 className="marcas-title" style={{ color: "#606060" }}>
                  tienda online
                </h4>
              </div>
              <div className="text-center">
                <h4 id="marcas-slogan" style={{ color: "#606060" }}>
                  encuentra lo que buscas, no solo de nuestras marcas
                  exclusivas, sino de una gran selección de productos en todas
                  las gamas de precios y diseños
                </h4>
              </div>
            </Col>
          </Row>
        </Container>

        <div className="height20" />
        <Container className="big-container">
          <Row>
            <Col sm={1} />
            <Col sm={2} xs={6} className="brand-col mx-auto">
              <a href="https://www.grasshc.com/Catalogo/SILLA-STILL-LIFE"
                rel="noopener noreferrer"
                target="_blank"
              >
                <div className="brand-wrapper">
                  <div className="brand-image-wrapper">
                    <div className="img-hover-zoom">
                      <GatsbyImage
                        image={getImage(data.shop_online_1)}
                        backgroundColor={false}
                        alt="silla still life"
                        layout="fullWidth"
                      />
                    </div>
                  </div>
                  <div className="text-center brand-button-wrapper">
                    <Button className="shop-button">still life</Button>
                  </div>
                </div>
              </a>
              <div className="height40" />
            </Col>
            <Col sm={2} xs={6} className="brand-col mx-auto">
              <a href="https://www.grasshc.com/BKF"
                rel="noopener noreferrer"
                target="_blank"
              >
                <div className="brand-wrapper">
                  <div className="brand-image-wrapper">
                    <div className="img-hover-zoom">
                      <GatsbyImage
                        image={getImage(data.shop_online_2)}
                        backgroundColor={false}
                        alt="silla bkf"
                      />
                    </div>
                  </div>
                  <div className="text-center brand-button-wrapper">
                    <Button className="shop-button">bkf</Button>
                  </div>
                </div>
              </a>
              <div className="height40" />
            </Col>
            <Col sm={2} xs={6} className="brand-col mx-auto">
              <a href="https://www.grasshc.com/ARC-PANEL-FONOABSORBENTE"
                rel="noopener noreferrer"
                target="_blank"
              >
                <div className="brand-wrapper">
                  <div className="brand-image-wrapper">
                    <div className="img-hover-zoom">
                      <GatsbyImage
                        image={getImage(data.shop_online_3)}
                        backgroundColor={false}
                        alt="arc panel fonoabsorbente"
                      />
                    </div>
                  </div>
                  <div className="text-center brand-button-wrapper">
                    <Button className="shop-button">arc panel</Button>
                  </div>
                </div>
              </a>
              <div className="height40" />
            </Col>
            <Col sm={2} xs={6} className="brand-col mx-auto">
              <a href="https://www.grasshc.com/ARUM-SILLA-CON-BRAZOS"
                rel="noopener noreferrer"
                target="_blank"
              >
                <div className="brand-wrapper">
                  <div className="brand-image-wrapper">
                    <div className="img-hover-zoom">
                      <GatsbyImage
                        image={getImage(data.shop_online_4)}
                        backgroundColor={false}
                        alt="arum silla con brazos"
                      />
                    </div>
                  </div>
                  <div className="text-center brand-button-wrapper">
                    <Button className="shop-button">arum</Button>
                  </div>
                </div>
              </a>
              <div className="height40" />
            </Col>
            <Col sm={2} xs={6} className="brand-col mx-auto">
              <a href="https://www.grasshc.com/FARO-TABURETE-ALTO-76-CM"
                rel="noopener noreferrer"
                target="_blank"
              >
                <div className="brand-wrapper">
                  <div className="brand-image-wrapper">
                    <div className="img-hover-zoom">
                      <GatsbyImage
                        image={getImage(data.shop_online_5)}
                        backgroundColor={false}
                        alt="faro taburete alto"
                      />
                    </div>
                  </div>
                  <div className="text-center brand-button-wrapper">
                    <Button className="shop-button">faro</Button>
                  </div>
                </div>
              </a>
              <div className="height40" />
            </Col>
            <Col sm={1} />
          </Row>

          <div className="height40" />
          <Row>
            <Col md={4} />
            <Col md={4} className="align-center">
              <a
                href="https://www.grasshc.com"
                rel="noopener noreferrer"
                target="_blank"
              >
                <Button className="brand-button">shop online</Button>
              </a>
            </Col>
          </Row>
          <div className="height40" />
          <div className="height40" />
        </Container>
      </div>
      <div id="novedades">
        <div className="height20" />
        <Instagram
          imagesArray={data.instagramImages.nodes}
          leftArrow={data.leftArrow}
          rightArrow={data.rightArrow}
        />
        <div className="height20" />
      </div>
      <div className="height40" />
      <Container className="big-container">
        <Row>
          <Col md={6} style={{ marginBottom: "40px" }}>
            <Link to="/quienes-somos">
              <div className="brand-wrapper">
                <div className="brand-image-wrapper">
                  <div className="img-hover-zoom">
                    <GatsbyImage
                      image={getImage(data.quienes_somos_daucodesigns)}
                      backgroundColor={false}
                      alt="quienes somos"
                    />
                  </div>
                </div>
                <div className="text-center brand-button-wrapper">
                  <Button className="brand-button">quienes somos</Button>
                </div>
              </div>
            </Link>
          </Col>
          <Col md={6}>
            <Link to="/proyectos">
              <div className="brand-wrapper">
                <div className="brand-image-wrapper">
                  <div className="img-hover-zoom">
                    <GatsbyImage
                      image={getImage(data.proyectos_dauco_principal_1)}
                      backgroundColor={false}
                      alt="quienes somos"
                    />
                  </div>
                </div>
                <div className="text-center brand-button-wrapper">
                  <Button className="brand-button">proyectos</Button>
                </div>
              </div>
            </Link>
          </Col>
        </Row>
      </Container>

      <div className="height40" />
      <div className="height40" />
      <div className="locations">
        <Container>
          <Row>
            <Col>
              <div className="text-center maps-text">
                <h4 className="marcas-title">donde encontrarnos</h4>
                <div className="height20" />
                <h4>
                  en nuestras tiendas físicas / showroom, donde podrás
                  <br />
                  probar, tocar y disfrutar de muchos de nuestros productos.
                </h4>
                <div className="height20" />
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col md={4} className="map-col">
              <div className="maps-text">
                <h3>Barcelona</h3>
                <p>
                  C/ Doctor Ferrán, 2-4. 08034
                  <br />
                  934 322 103
                  <br />
                  drferran@grasshc.com
                </p>
                <Button
                  className="footer-button social-networks"
                  onClick={e => handleMaps({ lat: 41.3885721, lng: 2.1251953 })}
                >
                  <Icon size={"40px"} icon={mapPin} />
                  <p style={{ fontSize: "14px", margin: 0 }}>ver mapa</p>
                </Button>
              </div>
              <MapModal />
            </Col>
            <Col md={4} className="map-col">
              <div className="maps-text">
                <h3>Barcelona</h3>
                <p>
                  Avda. Diagonal, 416. 08037
                  <br />
                  932 076 819
                  <br />
                  lespunxes@grasshc.com
                </p>
                <Button
                  className="footer-button social-networks"
                  onClick={e => handleMaps({ lat: 41.3980775, lng: 2.1643292 })}
                >
                  <Icon size={"40px"} icon={mapPin} />
                  <p style={{ fontSize: "14px", margin: 0 }}>ver mapa</p>
                </Button>
              </div>
            </Col>
            <Col md={4} className="map-col">
              <div className="maps-text">
                <h3>Madrid</h3>
                <p>
                  C/ Gregorio Benitez, 16. 28043
                  <br />
                  913 889 792
                  <br />
                  gregoriobenitez@grasshc.com
                </p>
                <Button
                  className="footer-button social-networks"
                  onClick={e => handleMaps({ lat: 40.4500451, lng: -3.648806 })}
                >
                  <Icon size={"40px"} icon={mapPin} />
                  <p style={{ fontSize: "14px", margin: 0 }}>ver mapa</p>
                </Button>
              </div>
            </Col>
          </Row>
          <div className="height40" />
          <Row>
            <Col md={4} />
            <Col md={4} className="align-center">
              <Link to="/showrooms">
                <Button className="brand-button">showrooms</Button>
              </Link>
            </Col>
          </Row>
          <div className="height40" />
        </Container>
      </div>
      <div className="height40" />
      <div className="height40" />

      <Footer />
    </>
  )
}

export default IndexPage

export const query = graphql`
  query pageData {
    blastation1P: file(
      relativePath: { eq: "brands/bla-station/blastation01P.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    bene_pixel: file(relativePath: { eq: "bene_pixel.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    bla_stattion_oppo: file(relativePath: { eq: "bla-stattion-oppo.jpeg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    acousticpearls1P: file(
      relativePath: { eq: "brands/acousticpearls/acousticpearls1P.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    bene5P: file(relativePath: { eq: "brands/bene/bene5P.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    blastation7P: file(
      relativePath: { eq: "brands/bla-station/blastation07P.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    wiesnerhager7P: file(
      relativePath: { eq: "brands/wiesner-hager/wiesnerhager7P.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    grassoler1P: file(
      relativePath: { eq: "brands/grassoler/grassoler1P.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    Inclass1P: file(relativePath: { eq: "brands/inclass/Inclass1P.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    Gotessons1P: file(
      relativePath: { eq: "brands/gotessons/Gotessons1P.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    Nardi1P: file(relativePath: { eq: "brands/nardi/Nardi1P.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    Artis1P: file(relativePath: { eq: "brands/artis/artis1P.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    Kastel1P: file(relativePath: { eq: "brands/kastel/kastel1P.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    fenabel1P: file(relativePath: { eq: "brands/fenabel/fenabel1P.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    systemtronic1P: file(relativePath: { eq: "brands/systemtronic/systemtronic01P.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    shop_online_1: file(
      relativePath: { eq: "online/shop_online_1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    shop_online_2: file(
      relativePath: { eq: "online/shop_online_2.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    shop_online_3: file(
      relativePath: { eq: "online/shop_online_3.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    shop_online_4: file(
      relativePath: { eq: "online/shop_online_4.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    shop_online_5: file(
      relativePath: { eq: "online/shop_online_5.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    quienes_somos_daucodesigns: file(
      relativePath: { eq: "quienes_somos_daucodesigns.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    proyectos_dauco_principal_1: file(
      relativePath: { eq: "projects/proyectos_dauco_principal_1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    instagramImages: allFile(
      filter: { relativePath: { regex: "/instagram/" } }
      sort: { fields: relativePath }
    ) {
      nodes {
        childrenImageSharp {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH)
        }
      }
    }
    leftArrow: file(relativePath: { eq: "left.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    rightArrow: file(relativePath: { eq: "right.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
  }
`
